import React from "react";
import circle1 from "../../assets/images/contact-page/semicircle1.png";
import circle2 from "../../assets/images/contact-page/semicircle2.png";
import rhombus from "../../assets/images/contact-page/rombhus.png";
import info from "../../assets/images/contact-page/info.png";
import line from "../../assets/images/CRM-img/line.svg";
import icon1 from "../../assets/images/contact-page/contact-location.png";
import icon2 from "../../assets/images/contact-page/contact-number.png";
import icon3 from "../../assets/images/contact-page/contact-whatsapp.png";
import msg from "../../assets/images/contact-page/contact-msg.png";
import linkedin from "../../assets/images/linkedin.png";
import x from "../../assets/images/contact-page/contact-twitter.png";
import sw from "../../assets/images/contact-page/social-world.png";
import fb from "../../assets/images/facebook.png";
import insta from "../../assets/images/instagram.png";
import ico1 from "../../assets/images/contact-page/form-person.svg";
import ico2 from "../../assets/images/contact-page/form-email.svg";
import ico3 from "../../assets/images/contact-page/form-no.svg";
import ico4 from "../../assets/images/contact-page/form-service.svg";
import ico5 from "../../assets/images/contact-page/form-msg.svg";
import contact from "../../assets/images/contact-page/form.png";

function Contact() {
  return (
    <div>
      <div className="contactInfo1">
        <img className="circle1" src={circle1} alt="circle" />

        <div className="contactInfoText">
          <h1>Contact us</h1>
          <p className="contactInfo1Head">
            Drop your <span>Query</span> here...
          </p>
          <p>
            We're here to help! If you have any questions or feedback, please
            don't hesitate to contact us. We'll get back to you as soon as
            possible.
          </p>
          <button className="contactInfoBtn">Contact</button>
        </div>
        <div className="contactInfoImage">
          <img src={info} alt="info" />
        </div>
      </div>
      <div className="contactInfo2">
        <img className="rombhus" src={rhombus} alt="rhombus" />
        <div className="contactInfoBoxes">
          <div className="contactInfoBox">
            <h3>Headquarter</h3>
            <img src={line} alt="underline" className="underline" />
            <div className="contactInfoBoxContent1">
              <div className="img-bg">
                <img src={icon1} alt="location" />
              </div>
              <p>
                Behind Gajraj Tata Motors, Near Vinoba Bhave University,Sindoor,
                Hazaribagh, Jharkhand 825301
              </p>
            </div>
            <div className="contactInfoBoxContent">
              <div className="img-bg">
                <img src={icon2} alt="phone" />
              </div>
              <p>+91 9294916452</p>
            </div>
            <div className="contactInfoBoxContent">
              <div className="img-bg">
                <img src={icon3} alt="whatsapp" />
              </div>
              <p>+91 9294916452</p>
            </div>
          </div>
          <div className="contactInfoBox">
            <h3>Customer Support</h3>
            <div className="contactInfoBoxContent1">
              <div className="img-bg">
                <img src={msg} alt="contact" />
              </div>
              <a href="mailto:info@techiesgateway.com">
                <p>info@techiesgateway.com</p>
              </a>
            </div>
            <div className="contactInfoBoxContent">
              <div className="img-bg">
                <img src={msg} alt="contact" />
              </div>
              <a href="mailto:support@techiesgateway.com">
                <p>support@techiesgateway.com</p>
              </a>
            </div>
            <div className="contactInfoBoxContent">
              <div className="img-bg">
                <img src={msg} alt="contact" />
              </div>
              <a href="mailto:contact@techiesgateway.com">
                <p>contact@techiesgateway.com</p>
              </a>
            </div>
          </div>
          <div className="contactInfoBox">
            <h3>Social Media</h3>
            <div className="contactInfoBoxContent3">
              <div className="contentSocialImage">
                <a
                  href="https://www.linkedin.com/company/techies-gateway/
"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="socials" />
                </a>
              </div>
              <div className="contentSocialImage">
                <a
                  href="https://x.com/techiesgateway?s=09
"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img src={x} alt="socials" />
                </a>
              </div>
              <div className="contentSocialImage">
                <a href="https://www.facebook.com/profile.php?id=61556968121199&mibextid=ZbWKwL">
                  {" "}
                  <img src={fb} alt="socials" />
                </a>
              </div>
              <div className="contentSocialImage">
                <a
                  href="https://www.techiesgateway.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img src={sw} alt="socials" />
                </a>
              </div>
              <div className="contentSocialImage">
                <a href="https://www.instagram.com/techiesgateway_?igsh=ejFtMDFtb2xwdmI3">
                  <img src={insta} alt="socials" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-form">
        <img className="circle2" src={circle2} alt="circle" />
        <div className="form-div">
          <form>
            <div className="formHeading">
              <h3>Get In Touch</h3>
              <p>We are here to grow your business</p>
              <img src={line} alt="underline" className="underline" />
            </div>
            <div className="form-box">
              <img src={ico1} alt="#" />
              <input type="text" placeholder="First Name" className="box-A" />
              <img src={ico1} alt="#" />
              <input type="text" placeholder="Last Name" className="box-A" />
            </div>
            <div className="form-box">
              <img src={ico2} alt="#" />
              <input type="email" placeholder="Email Address" />
            </div>
            <div className="form-box">
              <img src={ico3} alt="#" />
              <input type="phone" placeholder="Phone No" />
            </div>
            <div className="form-box">
              <img src={ico4} alt="#" />
              <input type="email" placeholder="Service" />
            </div>
            <div className="form-box">
              <img className="textarea-svg" src={ico5} alt="#" />
              <textarea
                name="message"
                id="text-area"
                rows="10"
                placeholder="Message"
              ></textarea>
            </div>
            <button className="contactInfoBtn">Submit</button>
          </form>
        </div>
        <div className="form-image">
          <img src={contact} alt="contact here" />
        </div>
      </div>
    </div>
  );
}

export default Contact;
